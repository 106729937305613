exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_widgets_2wAzTEOvMm-_wgbqG0wjgN {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_37Xcf64cQJjgS5LdqFXGJ5 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_20Ovd-bxH8tJp_5oH7Cmqs {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2sx-B7egSph4lnhu5IYiR4 {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_widgets": "IA_widgets_2wAzTEOvMm-_wgbqG0wjgN",
	"IA_filters": "IA_filters_37Xcf64cQJjgS5LdqFXGJ5",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_20Ovd-bxH8tJp_5oH7Cmqs",
	"IA_paginationPageCount": "IA_paginationPageCount_2sx-B7egSph4lnhu5IYiR4"
};