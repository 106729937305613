exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_profile_3B3Lh4ZV5Do76_mSG3EpaM {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.IA_divider_1S2PTSY4YUM-0vvbe_UEsA, .IA_dividerDarkMode_oYaEbCVadtyVyxPIvocdy {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_dividerDarkMode_oYaEbCVadtyVyxPIvocdy {\n  background-color: #a8a8a8;\n}\n\n.IA_widgetType_3-E6BeXVgg3WwtIRIdw5HZ {\n  margin-bottom: 15px;\n  width: 75px;\n  cursor: pointer;\n}\n\n.IA_widgetTypeLabel_23zBnU4pMyt2ouu0yYx4gR {\n  font-size: 12px;\n  float: left;\n  clear: both;\n  width: 90%;\n  margin-top: 2px;\n}\n\n.IA_swatches_2_T6EEnGiFbJ2nMIGd0QSJ {\n  width: 100%;\n  clear: both;\n  position: relative;\n  float: left;\n}", ""]);

// Exports
exports.locals = {
	"IA_profile": "IA_profile_3B3Lh4ZV5Do76_mSG3EpaM",
	"IA_divider": "IA_divider_1S2PTSY4YUM-0vvbe_UEsA",
	"IA_dividerDarkMode": "IA_dividerDarkMode_oYaEbCVadtyVyxPIvocdy",
	"IA_widgetType": "IA_widgetType_3-E6BeXVgg3WwtIRIdw5HZ",
	"IA_widgetTypeLabel": "IA_widgetTypeLabel_23zBnU4pMyt2ouu0yYx4gR",
	"IA_swatches": "IA_swatches_2_T6EEnGiFbJ2nMIGd0QSJ"
};