exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaSmall_2g5KVW4cOKhPYpq4BxRYm1 {\n  font-size: 12px;\n  float: left;\n  margin-top: 3px;\n  display: block;\n  width: 100%;\n}\n\n.IA_personaSmall_2g5KVW4cOKhPYpq4BxRYm1 .IA_personaSmallProfileImageWrapper_2SzvGXI8aD7bRV1HkAAGFO {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n.IA_personaSmall_2g5KVW4cOKhPYpq4BxRYm1 .IA_personaSmallProfileImage_JsqbsJpMbLJvQSl0nhAyd {\n  height: 32px;\n  width: 32px;\n  border-radius: 30px;\n  background-position: center;\n  margin-top: -32px;\n  left: -1px;\n  position: relative;\n  float: left;\n  background-size: cover;\n}\n\n.IA_personaSmall_2g5KVW4cOKhPYpq4BxRYm1 .IA_personaSmallProfileImageText_2CFLNX1FF2t1GrnfBlPlHW {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n.IA_personaSmall_2g5KVW4cOKhPYpq4BxRYm1 .IA_personaSmallName_2CSaYGTNagGPRFdzG4X7ag {\n  float: none;\n  width: calc(100% - 40px);\n  height: 16px;\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  font-weight: 600;\n  white-space: nowrap;\n}\n\n.IA_personaSmall_2g5KVW4cOKhPYpq4BxRYm1 .IA_personaSmallMetadata_dOvgbRn74bA8IVHvzNvIv {\n  float: none;\n  width: calc(100% - 40px);\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  color: #858585;\n  margin-top: 2px;\n}\n\n.IA_personaSmall_2g5KVW4cOKhPYpq4BxRYm1 .IA_personaSmallModifiedDate_20nOa10_xHdQ6LQdrwFBDM {\n  float: none;\n  height: 12px;\n  width: 100%;\n  margin-top: 1px;\n}", ""]);

// Exports
exports.locals = {
	"IA_personaSmall": "IA_personaSmall_2g5KVW4cOKhPYpq4BxRYm1",
	"IA_personaSmallProfileImageWrapper": "IA_personaSmallProfileImageWrapper_2SzvGXI8aD7bRV1HkAAGFO",
	"IA_personaSmallProfileImage": "IA_personaSmallProfileImage_JsqbsJpMbLJvQSl0nhAyd",
	"IA_personaSmallProfileImageText": "IA_personaSmallProfileImageText_2CFLNX1FF2t1GrnfBlPlHW",
	"IA_personaSmallName": "IA_personaSmallName_2CSaYGTNagGPRFdzG4X7ag",
	"IA_personaSmallMetadata": "IA_personaSmallMetadata_dOvgbRn74bA8IVHvzNvIv",
	"IA_personaSmallModifiedDate": "IA_personaSmallModifiedDate_20nOa10_xHdQ6LQdrwFBDM"
};