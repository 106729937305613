exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_images_3awbgMAd8zaWuSbEh0ykoP {\n  width: 100%;\n  float: left;\n  position: relative;\n  margin-bottom: 20px;\n}\n\n.IA_imageRow_3e9poO0Dx5ItjJt4wLUP-H {\n  width: 100%;\n  flex-flow: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  float: left;\n  position: relative;\n  margin-top: 10px;\n}\n\n.IA_thumbnail_1qnnFqT5TwKg6O41QhU8t3 {\n  height: 100%;\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  position: relative;\n  margin: 5px;\n  float: left;\n  cursor: pointer;\n  border-radius: 5px;\n}\n\n.IA_imageResultInfo_1ca9Kt0-YyT8BseVOPXKo9 {\n  width: 100%;\n  margin-top: 20px;\n  text-align: center;\n  color: gray;\n}\n\n\n.IA_spinner_6FoBnmvUwlPSPtIypteWe {\n  height: 20px;\n  width: 20px;\n  margin-left: calc(50% - 10px);\n  margin-top: 20px;\n  -webkit-animation: spin_HGe3nw2Wf7hoR7JvJ9JAQ 1s linear infinite;\n  -moz-animation: spin_HGe3nw2Wf7hoR7JvJ9JAQ 1s linear infinite;\n  animation: spin_HGe3nw2Wf7hoR7JvJ9JAQ 1s linear infinite;\n  float: left;\n  position: relative;\n}\n\n@-moz-keyframes spin_HGe3nw2Wf7hoR7JvJ9JAQ {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_HGe3nw2Wf7hoR7JvJ9JAQ {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_HGe3nw2Wf7hoR7JvJ9JAQ {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n\n", ""]);

// Exports
exports.locals = {
	"IA_images": "IA_images_3awbgMAd8zaWuSbEh0ykoP",
	"IA_imageRow": "IA_imageRow_3e9poO0Dx5ItjJt4wLUP-H",
	"IA_thumbnail": "IA_thumbnail_1qnnFqT5TwKg6O41QhU8t3",
	"IA_imageResultInfo": "IA_imageResultInfo_1ca9Kt0-YyT8BseVOPXKo9",
	"IA_spinner": "IA_spinner_6FoBnmvUwlPSPtIypteWe",
	"spin": "spin_HGe3nw2Wf7hoR7JvJ9JAQ"
};