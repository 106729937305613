exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listItem_24YtMlD8SJUkdT-fOgKdOa {\n  position: relative;\n  width: 100%;\n  float: left;\n  box-sizing: border-box;\n}\n\n.IA_title_1-mNNhCOBYp_FwtdCIFrbh {\n  float: left;\n}\n\n.IA_teaser_dIgn3o-FIE76gH5pnaZxq {\n  float: left;\n  display: -webkit-box;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.IA_startDate_2TebecH6yEWrHOLU6Y8Pr6 {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n}\n\n.IA_now_3L35SiP_caErmMld4nc_p- {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-weight: bold;\n}\n\n.IA_startDateendDateDivider_VPMRW5_A9shM1HDoT7fGK {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n}\n\n.IA_endDate_20eaWPoDL7zlumge4dE6on {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_coverOverlayToday_20p1GJ9-emRPUawq10QGiA {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n}\n\n.IA_coverOverlayMonth_SnC_SHSNfpt4jC4uQWKW9 {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n}\n\n.IA_coverOverlayDay_3Ef6Qa8BQE_K3ZZTuKmFWx {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_byline_Fw9nEjQwjDC_vCX7YWE_S {\n  float: left;\n  clear: both;\n  position: relative;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_listItem": "IA_listItem_24YtMlD8SJUkdT-fOgKdOa",
	"IA_title": "IA_title_1-mNNhCOBYp_FwtdCIFrbh",
	"IA_teaser": "IA_teaser_dIgn3o-FIE76gH5pnaZxq",
	"IA_startDate": "IA_startDate_2TebecH6yEWrHOLU6Y8Pr6",
	"IA_now": "IA_now_3L35SiP_caErmMld4nc_p-",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_VPMRW5_A9shM1HDoT7fGK",
	"IA_endDate": "IA_endDate_20eaWPoDL7zlumge4dE6on",
	"IA_coverOverlayToday": "IA_coverOverlayToday_20p1GJ9-emRPUawq10QGiA",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_SnC_SHSNfpt4jC4uQWKW9",
	"IA_coverOverlayDay": "IA_coverOverlayDay_3Ef6Qa8BQE_K3ZZTuKmFWx",
	"IA_byline": "IA_byline_Fw9nEjQwjDC_vCX7YWE_S"
};