import { Parser } from 'xml2js';
import { IRss, IRssItem } from '../interfaces/IRss';

export default class RssService {

  public static getFeed(url: string): Promise<IRss> {
    return new Promise((resolve) => {
      fetch(`https://rss.api.intraactive.net/content?url=${encodeURIComponent(url)}`, {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }),
        method: "GET",
      })
      .then((response) => {
        if (!response.ok) {
          return response.status;
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
      });
    });
  }

  public static getDescription = (inputString: string, maxLength: number): { __html: string } => {
    let result = '';

    if (!inputString || inputString.length < 1) {
      return { __html: result };
    }
    let div = document.createElement('div');
    div.innerHTML = inputString;
    inputString = (div.textContent || div.innerText || '').replace(/\&nbsp;/ig, '').trim();

    if (inputString.length < maxLength) {
      result = inputString;
    }
    else {
      result = inputString.substr(0, maxLength).trim() + '...';
    }
    return { __html: result };
  }

  public static getDirectFeed = async (feedUrl: string, maxCount?: number): Promise<IRss> => {
    const self = this;
    return new Promise<IRss>(async (resolve, reject) => {
      try {
        const feed: any = await self.directFeed(feedUrl);  
        if (feed) {
          if (maxCount) {
            feed.rss.channel.item.length = maxCount;
          }
          // Validating if the result is an Array, if is not, adding it to so the whole feature can handle it
          if (!Array.isArray(feed.rss.channel.item)) {
            feed.rss.channel.item = [feed.rss.channel.item];
          }
          self.detectImage(feed.rss.channel.item);
          resolve(feed.rss.channel);
        }
        reject();
      }
      catch (ex) {
        console.log(ex);
        reject();
      }
    });
  }

  private static findImage = (object: IRssItem): string => {
    let result;
    const reg = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/;
    Object.keys(object).some((k) => {
      // tv2 and dr feed uses media:content for images
      if (k === "media:content" && object[k].url) {
        result = object[k].url;
        return true;
      }

      if (k !== 'description' && reg.test(object[k])) {
        result = object[k];
        if (Array.isArray(result)) {
          result = object[k][0];
        }
        return true;
      }

      if (object[k] && typeof object[k] === 'object') {
        result = this.findImage(object[k]);
        return result !== undefined;
      }
    });
    return result;
  }

  private static detectImage = (feedItem: any) => {
    feedItem.map(item => {
      // First look for enclosure else look in other pros    
      if (item.enclosure && item.enclosure.url) {
        item.image = item.enclosure.url;
      } else {
        const imageFound = this.findImage(item);
        if (imageFound) {
          item.image = imageFound;
        }
      }
    });
  }

  private static extractFeeds = (response: any): IRss => {
    const parser = new Parser({ explicitArray: false, mergeAttrs: true });
    let feed: IRss;
    parser.parseString(response, (err: any, result: IRss) => {
      if (err) {
        console.warn(err);
      }
      feed = result;
    });

    return feed;
  }

  private static directFeed = async (feedUrl: string): Promise<IRss> => {
    try {
      const url = feedUrl.indexOf("rss.api.intraactive.net") === -1 ? `https://rss.api.intraactive.net/content?url=${encodeURIComponent(feedUrl)}` : feedUrl;
      const response = await this.getFeed(url);
      const feed = this.extractFeeds(response);
      return feed;
    }
    catch (ex) {
      console.error(ex);
      return null;
    }
  }
}