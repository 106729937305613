exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_button_DWHDRqo7cfWXPMc5u-mx3 {\n  border-style: none;\n  height: 30px;\n  width: auto;\n  float: left;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 14px;\n  line-height: 30px;\n  font-weight: 400;\n  cursor: pointer;\n  outline: none;\n  color:#333333;    \n  background-color:#f4f4f4;\n  padding: 0;\n  border-radius: 2px;\n}\n\n.IA_button_DWHDRqo7cfWXPMc5u-mx3 .spinner_3ngP8EBfnIKzFwd388N-SS {\n  width: 20px;\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_10mtSbI1n7SMkYeNDYhvj- 700ms linear infinite;\n  -moz-animation: spin_10mtSbI1n7SMkYeNDYhvj- 700ms linear infinite;\n  animation: spin_10mtSbI1n7SMkYeNDYhvj- 700ms linear infinite;\n }\n\n.IA_button_DWHDRqo7cfWXPMc5u-mx3 .label_2bLd3O3WlyQb0Tcy2qjxja {\n  text-align: center;\n}\n\n.IA_button_DWHDRqo7cfWXPMc5u-mx3 .icon_2W6Kqp_vcwrYLA3BPommPZ {\n  float: left;\n  margin-top: 3px;\n  margin-right: 5px;\n  line-height: normal;\n}\n@-moz-keyframes spin_10mtSbI1n7SMkYeNDYhvj- {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_10mtSbI1n7SMkYeNDYhvj- {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_10mtSbI1n7SMkYeNDYhvj- {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_button": "IA_button_DWHDRqo7cfWXPMc5u-mx3",
	"spinner": "spinner_3ngP8EBfnIKzFwd388N-SS",
	"spin": "spin_10mtSbI1n7SMkYeNDYhvj-",
	"label": "label_2bLd3O3WlyQb0Tcy2qjxja",
	"icon": "icon_2W6Kqp_vcwrYLA3BPommPZ"
};