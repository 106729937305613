exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_likesAndComments_ER600npHO4j8ft3_Pszxm {\n  float: left;\n  width: 100%;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: auto;\n  padding-bottom: 10px;\n  background-color: #f6f6f6;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n}\n\n.IA_commandBar_1l8YihH1NxfkAghEXLKDfr {\n  width: 100%;\n  height: 30px;\n  float: left;\n  box-sizing: border-box;\n  line-height: 30px;\n}\n\n.IA_commandBarButton_23lvgftsZdAGEhYCAvXd8g {\n  min-width: 40px;\n  padding-right: 10px;\n  float: left;\n  cursor: pointer;\n}\n\n.IA_commandBarButtonIcon_371y3MM689EW43Zfd2Ucxw {\n  margin-right: 5px;\n  margin-top: 1px;\n  float: left;\n}\n\n.IA_commandBarButtonText_1nVEXAaEIu5iocsPVHAagw {\n  float: left;\n  width: auto;\n  font-size: 14px;\n}\n\n.IA_comments_32sJU4uXKddrn4ddsyrwMx {\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n  clear: both;\n}\n\n.IA_likes_1Gb-SKkD1gkbtSs4Oo1rD4 {\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.IA_spinner_2UT88jPIAN-6PiplTgK5mx {\n  -webkit-animation: spin_2Syh7PhEwXHK_r3cQSC3lM 700ms linear infinite;\n  -moz-animation: spin_2Syh7PhEwXHK_r3cQSC3lM 700ms linear infinite;\n  animation: spin_2Syh7PhEwXHK_r3cQSC3lM 700ms linear infinite;\n  height: 15px;\n  width: 15px;\n  background-position: center;\n  background-size: 15px;\n  position: relative;\n  float: left;\n  clear: both;\n  opacity: 0.5;\n}\n\n.IA_personWhoLiked_1qQDsVALOYawk7MzPb74tp {\n  padding-bottom: 5px;\n  padding-top: 5px;\n  border-bottom: 1px solid #eeeeee;\n  width: calc(100% - 15px);\n}\n\n@-moz-keyframes spin_2Syh7PhEwXHK_r3cQSC3lM {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_2Syh7PhEwXHK_r3cQSC3lM {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_2Syh7PhEwXHK_r3cQSC3lM {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_likesAndComments": "IA_likesAndComments_ER600npHO4j8ft3_Pszxm",
	"IA_commandBar": "IA_commandBar_1l8YihH1NxfkAghEXLKDfr",
	"IA_commandBarButton": "IA_commandBarButton_23lvgftsZdAGEhYCAvXd8g",
	"IA_commandBarButtonIcon": "IA_commandBarButtonIcon_371y3MM689EW43Zfd2Ucxw",
	"IA_commandBarButtonText": "IA_commandBarButtonText_1nVEXAaEIu5iocsPVHAagw",
	"IA_comments": "IA_comments_32sJU4uXKddrn4ddsyrwMx",
	"IA_likes": "IA_likes_1Gb-SKkD1gkbtSs4Oo1rD4",
	"IA_spinner": "IA_spinner_2UT88jPIAN-6PiplTgK5mx",
	"spin": "spin_2Syh7PhEwXHK_r3cQSC3lM",
	"IA_personWhoLiked": "IA_personWhoLiked_1qQDsVALOYawk7MzPb74tp"
};