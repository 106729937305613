exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenuItem_3R5vkxe8qO-UOjtfo78K6Y {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.IA_title_ssnr-wSfjr8eiMeHg8LXj {\n  float: left;\n  clear: both;\n  position: relative;\n  font-weight: bold;\n}\n\n.IA_contentWrapper_2IPZeF9c-x-DMbpIo54tNf {\n  float: left;\n  overflow: hidden;\n  width: 100%;\n  position: relative;\n}\n\n.IA_text_34oqI2XxVwF5nHkOpZXKZj {\n  float: left;\n  clear: both;\n}\n\n.IA_text_34oqI2XxVwF5nHkOpZXKZj p,\n.IA_text_34oqI2XxVwF5nHkOpZXKZj ul {\n  margin: 0;\n}\n\n.IA_progressBarBackground_2ywzSquEAouwtyvlRFoLAO {\n  position: relative;\n  float: left;\n  clear: both;\n  position: relative;\n}\n\n.IA_startDate_2VweVY_eAa03AWhxqCGrTJ {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n}\n\n.IA_byline_7xxypnLo5gkXd9zBLauW6 {\n  float: left;\n  clear: both;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenuItem": "IA_canteenMenuItem_3R5vkxe8qO-UOjtfo78K6Y",
	"IA_title": "IA_title_ssnr-wSfjr8eiMeHg8LXj",
	"IA_contentWrapper": "IA_contentWrapper_2IPZeF9c-x-DMbpIo54tNf",
	"IA_text": "IA_text_34oqI2XxVwF5nHkOpZXKZj",
	"IA_progressBarBackground": "IA_progressBarBackground_2ywzSquEAouwtyvlRFoLAO",
	"IA_startDate": "IA_startDate_2VweVY_eAa03AWhxqCGrTJ",
	"IA_byline": "IA_byline_7xxypnLo5gkXd9zBLauW6"
};