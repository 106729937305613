exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".wordImagePasteErrorMessageContainer_2mbNxZmtwytj6YZIwcLlzB {\n  display: flex;\n  justify-content: center;\n}\n\n.wordImagePasteErrorMessage_3Wnzs8eRx8l_1wN7DkpcWV {\n  display: flex;\n  justify-content: center;\n  background-color: orange;\n  border-radius: 5px;\n  transition: all .5s, height .75s;\n  width: 100%;\n  position: relative;\n  text-align: center;\n  align-items: center;\n  padding-left: 15px;\n  padding-right: 30px;\n}\n\n.editorHasFocus_Yz918Z6aDXYs3cSV24Big [class*=\"fr-toolbar\"] {\nz-index: 3000000 !important;\n}\n\n.editorHasBlur_3_A1WfZ34Kb0AjDkP47Hxr [class*=\"fr-toolbar\"] {\nz-index: 1 !important;\n}", ""]);

// Exports
exports.locals = {
	"wordImagePasteErrorMessageContainer": "wordImagePasteErrorMessageContainer_2mbNxZmtwytj6YZIwcLlzB",
	"wordImagePasteErrorMessage": "wordImagePasteErrorMessage_3Wnzs8eRx8l_1wN7DkpcWV",
	"editorHasFocus": "editorHasFocus_Yz918Z6aDXYs3cSV24Big",
	"editorHasBlur": "editorHasBlur_3_A1WfZ34Kb0AjDkP47Hxr"
};