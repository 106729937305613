import * as React from 'react';
import { IMessageCategory } from '../../../interfaces/IMessage';
import { Helper } from '../../../Helper';

export interface IAMessageCategoryProps {
  category: IMessageCategory;
  isLastCategory?: boolean;
  allowOnlyOneLineOfText?: boolean;
  useTransparentAsDefaultBackgroud?: boolean;
  textColorWhenTransparentBackground?: string;
  archiveLink?: string;
}

export class IAMessageCategory extends React.Component<IAMessageCategoryProps> {

  private generateLink(): URL {
    try {
      const url = new URL(this.props.archiveLink);
      url.searchParams.set("categories", this.props.category.id.toString());
      return url;
    } catch (error) {
      return null;
    }
  }

  public render(): JSX.Element {
    const backgroundColor = this.props.useTransparentAsDefaultBackgroud ? "transparent" : Helper.getCategoriesDefaultBackgroundColor();
    const leftRightPadding = this.props.useTransparentAsDefaultBackgroud && !this.props.category?.bgColor ? 0 : 8;
    const topBottomPadding = this.props.useTransparentAsDefaultBackgroud && !this.props.category?.bgColor ? 2 : 5;
    let textColor = this.props.category?.textColor ?? Helper.getCategoriesDefaultTextColor();
    if (this.props.useTransparentAsDefaultBackgroud && this.props.textColorWhenTransparentBackground) {
      textColor = this.props.textColorWhenTransparentBackground;
    }

    const style: React.CSSProperties = {
      paddingTop: topBottomPadding,
      paddingBottom: topBottomPadding,
      paddingRight: leftRightPadding,
      paddingLeft: leftRightPadding,
      borderRadius: 5,
      fontSize: 11,
      fontWeight: "bold",
      lineHeight: "13px",
      marginRight: this.props.isLastCategory ? 0 : Helper.messageCategoryMargin,
      backgroundColor: this.props.category?.bgColor ?? backgroundColor,
      color: textColor,
      marginBottom: 5,
      maxHeight: this.props.allowOnlyOneLineOfText ? 13 : undefined,
      overflow: this.props.allowOnlyOneLineOfText ? "hidden" : undefined,
      textOverflow: this.props.allowOnlyOneLineOfText ? "ellipsis" : undefined,
      whiteSpace: this.props.allowOnlyOneLineOfText ? "nowrap" : undefined,
      textDecoration: "none"
    }
    return (this.props.archiveLink ?
      <a
        style={style}
        href={this.generateLink()?.toString()}
        target="_blank"
      >
        {this.props.category?.name}
      </a> 
    :
      <div
        style={style}
      >
        {this.props.category?.name}
      </div>
    );
  }
}