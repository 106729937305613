exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_license_258ygrJM8161LuQcr7R3pA {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.IA_licenseSlider_2dI217HT-OaNdIDdU29dAg {\n  -webkit-appearance: none;\n  width: 100%;\n  height: 5px;\n  background: #ffc885;\n  outline: none;\n  -webkit-transition: .2s;\n  transition: opacity .2s;\n  border-radius: 5px;\n}\n\n.IA_licenseSlider_2dI217HT-OaNdIDdU29dAg::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  width: 25px;\n  height: 25px;\n  border-radius: 25px;\n  background: #ffffff;\n  cursor: pointer;\n}\n\n.IA_licenseSlider_2dI217HT-OaNdIDdU29dAg::-moz-range-thumb {\n  width: 25px;\n  height: 25px;\n  border-radius: 25px;\n  background: #ffffff;\n  cursor: pointer;\n}\n\n.IA_licenseLabel_2ThKiVhL8bJt0UPPpdZokA {\n  font-size: 90px;\n  text-align: center;\n  width: 100%;\n  clear: both;\n  float: left;\n}\n\n.IA_licensePrize_3LrtEeVoWu9zlYUi0sXFXr {\n  font-size: 16px;\n  text-align: center;\n  margin-top: 20px;\n}\n\n.IA_licenseBox_3djfmQ_-AJDS9d0Cx1GPLZ {\n  background-color: #fc8a01;\n  border-radius: 10px;\n  margin-top: 30px;\n  padding: 20px 40px;\n  text-align: left;\n  color: #ffffff;\n  position: relative;\n  float: left;\n  clear: both;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.IA_licenseBox_3djfmQ_-AJDS9d0Cx1GPLZ h1 {\n  float: left;\n}\n\n.IA_pivotContent_1F3FrbprIZAcLNX8E4Jhu6 {\n  width: 100%;\n  float: left;\n  overflow: hidden;\n}", ""]);

// Exports
exports.locals = {
	"IA_license": "IA_license_258ygrJM8161LuQcr7R3pA",
	"IA_licenseSlider": "IA_licenseSlider_2dI217HT-OaNdIDdU29dAg",
	"IA_licenseLabel": "IA_licenseLabel_2ThKiVhL8bJt0UPPpdZokA",
	"IA_licensePrize": "IA_licensePrize_3LrtEeVoWu9zlYUi0sXFXr",
	"IA_licenseBox": "IA_licenseBox_3djfmQ_-AJDS9d0Cx1GPLZ",
	"IA_pivotContent": "IA_pivotContent_1F3FrbprIZAcLNX8E4Jhu6"
};