import * as React from 'react';
import ReactPlayer from 'react-player';
import { Helper } from '../../../../Helper';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { PlayHelper } from '../../../playAdmin/PlayHelper';
import { Image } from '../image/Image';
import * as styles from './Video.css';
import { IndexDbFileService } from '../../../../services/IndexDbFileService';

export interface IProps {
  type: "video" | "youtube" | "vimeo" | "video23" | "screen9";
  videoUrl: string;
  width: number | string;
  height: number | string;
  position?: "absolute" | "relative";
  marginLeft?: number | string;
  marginTop?: number | string;
  backgroundColor?: string;
  showTitle?: boolean;
  title?: string;
  playerHeight?: number;
  playerWidth?: number;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  willRestart: () => void;
  autoPlay?: boolean;
  style?: React.CSSProperties;
  onDuration?: (duration: number) => void;
  onError?: () => void;
}

export interface IState {
  isLoading: boolean;
  isKiosk: boolean;
  isPlaying: boolean;
  videoUrl: string;
}

export class Video extends React.Component<IProps, IState> {
  private indexDbFileService: IndexDbFileService = new IndexDbFileService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: true,
      isKiosk: PlayHelper.isReplayKiosk(),
      isPlaying: props.autoPlay == undefined ? true : props.autoPlay,
      videoUrl: null,
    };
  }

  public componentDidMount(): void {
    if (this.props.type !== "screen9") {
      this.indexDbFileService.getCachedFileUrl(this.props.videoUrl).then((newUrl) => {
        this.setState({ isLoading: false, videoUrl: newUrl });
      })
    } else {
      this.handleScreen9();
    }
  };

  private handleScreen9(): void {
    // Get screen9 video url
    let videoUrl;
    let videoUrlArrray = this.props.videoUrl?.split('"embedUrl": ');
    if (videoUrlArrray?.length > 1) {
      videoUrlArrray = videoUrlArrray[1].split('}</script>');
    }
    if (videoUrlArrray?.length > 1) {
      videoUrl = videoUrlArrray[0]?.replace(/"/g, "");
    }
    if (!videoUrl?.includes("autoplay=1")) {
      videoUrl += "?autoplay=1";
    }
    this.setState({ isLoading: false, videoUrl });
    // Get screen9 video duration
    let duration = 0;
    let seconds = 0;
    let minutes;
    let durationArrray = this.props.videoUrl?.split('"duration": "PT');
    if (durationArrray?.length > 1 && durationArrray[1]?.includes('M') && durationArrray[1]?.includes('S')) {
      minutes = parseInt(durationArrray[1].split('M')[0]);
      seconds = parseInt(durationArrray[1].split('M')[1].replace('S', ''));
    }
    if (minutes) {
      duration = minutes * 60;
    }
    if (seconds) {
      duration += seconds;
    }
    this.props.onDuration(duration);
  }

  public render(): JSX.Element {

    const style: React.CSSProperties = {
      position: this.props.position ?? "absolute",
      marginLeft: this.props.marginLeft ?? 0,
      marginTop: this.props.marginTop ?? 0,
      backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "transparent",
      ...this.props.style
    }
    return (<>
      {/* {this.props.type === "video23" ?
        <div
          style={{
            width: this.props.width,
            height: this.props.height,
            ...style
          }}
        >
          <iframe
            src={this.state.isKiosk ? this.props.videoUrl.replace("autoMute=1", "") : this.props.videoUrl}
            width={this.props.width}
            height={this.props.height}
            frameBorder="0"
            scrolling="no"
            allow="autoplay"
          />
        </div>
        : */}
      {!this.state.isLoading &&
        (this.props.type === "screen9" ?
          <iframe
            id="IA_Iframe_ym9u9e_ube2ei"
            width="100%"
            height="100%"
            allow="autoplay"
            src={this.state.videoUrl}
            style={{ float: "none" }}
          />
          :
          <ReactPlayer
            className="react-player"
            url={this.state.videoUrl}
            width={this.props.width}
            height={this.props.height}
            playing={this.state.isPlaying}
            muted={!this.state.isKiosk}
            style={style}
            onStart={() => this.setState({ isLoading: false })}
            onError={() => {
              console.log("Video failed");
              this.props.onError && this.props.onError();
            }}
            onEnded={() => {
              this.props.willRestart()
              this.setState({ isPlaying: false }, () => {
                setTimeout(() => {
                  this.setState({ isPlaying: true });
                }, 100);
              })
            }}
            onDuration={this.props.onDuration ? (duration: number) => this.props.onDuration(duration) : undefined}
          />
        )
      }
      {/* } */}
      {this.state.isLoading && this.props.videoUrl &&
        <>
          {this.props.type === "youtube"
            ?
            <div>
              <Image
                imageUrl={Helper.getYouTubeThumbnailFromURL(this.props.videoUrl, "maxresdefault")}
                isActive={true}
                width={this.props.width}
                height={this.props.height}
                environment={this.props.environment}
                profileId={this.props.profileId}
                tenantId={this.props.tenantId}
                backgroundColor={this.props.backgroundColor}
              />
            </div>
            :
            <div
              style={{
                width: this.props.width,
                height: this.props.height,
                ...style
              }}
              className={styles.IA_videoSkeleton}
            />
          }
        </>
      }
    </>);
  }


}