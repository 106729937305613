exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_cardFooter_12jrzYKhFkWP5Uwu_jwnmQ {\n  height: 40px;\n  width: calc(100% - 20px);\n  box-sizing: border-box;\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  margin: 0 10px;\n  border-top: 1px solid #eeeeee;\n}\n\n.IA_cardContainer_3M_4uTAIuJXt2giIu8_cos {\n  float: none;\n}\n\n.IA_cardBarBtn_KpY0XO3gLmi2w0T1Xozi_ {\n  float: right;\n  width: 20px;\n  height: 39px;\n  text-align: center;\n  cursor: pointer;\n  line-height: 40px;\n  border-radius: 5px;\n}\n\n.IA_cardBarBtn_KpY0XO3gLmi2w0T1Xozi_:hover {\n  background-color: rgba(0, 0, 0, 0.10);\n}\n\nul.IA_cardBarItems_2MolM4A2oOFsBviGv1nDiY {\n  background-color: #FFF;\n  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;\n  margin: 0;\n  padding: 0;\n  position: absolute;\n  z-index: 10000;\n  min-width: 180px;\n  right: 0;\n  bottom: 40px;\n  border-radius: 5px;\n}\n\n.IA_commandBarItem_PYrDymFqdwoep0eMPFoJ6 {\n  width: 100%;\n  list-style: none;\n  font-size: 12px;\n  padding: 8px;\n  cursor: pointer;\n  clear: both;\n  height: 30px;\n  box-sizing: border-box;\n}\n\n.IA_commandBarItem_PYrDymFqdwoep0eMPFoJ6:hover {\n  background-color: #eeeeee;\n}\n\nul.IA_cardBarItems_2MolM4A2oOFsBviGv1nDiY li div {\n  display: inline-block;\n}\n\nul.IA_cardBarItems_2MolM4A2oOFsBviGv1nDiY li:hover {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\nul.IA_cardBarItems_2MolM4A2oOFsBviGv1nDiY li svg {\n  margin-right: 8px;\n  position: relative;\n  top: 2px;\n}\n\n.IA_cardFooterLeftText_1ZJmyd6QYWzlfaxEtJ2pT0 {\n  float: left;\n  margin-top: 8px;\n  padding: 2px 5px;\n  border-radius: 5px;\n  background-color: rgba(0,0,0,0.1);\n}\n", ""]);

// Exports
exports.locals = {
	"IA_cardFooter": "IA_cardFooter_12jrzYKhFkWP5Uwu_jwnmQ",
	"IA_cardContainer": "IA_cardContainer_3M_4uTAIuJXt2giIu8_cos",
	"IA_cardBarBtn": "IA_cardBarBtn_KpY0XO3gLmi2w0T1Xozi_",
	"IA_cardBarItems": "IA_cardBarItems_2MolM4A2oOFsBviGv1nDiY",
	"IA_commandBarItem": "IA_commandBarItem_PYrDymFqdwoep0eMPFoJ6",
	"IA_cardFooterLeftText": "IA_cardFooterLeftText_1ZJmyd6QYWzlfaxEtJ2pT0"
};