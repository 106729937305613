exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_gettingStartedOverlay_1adekEgCezaZYHusBYrzoq {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  z-index: 999999;\n  box-sizing: border-box;\n}\n\n.IA_headline_3EoAiWAdYlWVE181TaWFVn {\n  position: relative;\n  text-align: center; \n  font-size: 24px;\n  width: 100%;\n}\n\n.IA_text_1spS4mAI0ulrQUffZZowqT {\n  position: relative;\n  text-align: center;\n  margin-top: 20px;\n  font-size: 16px;\n  width: 100%;\n}\n\n.IA_video_3blFqvv02g5YdTVwZDfsje {\n  position: relative;\n  margin-top: 30px;\n  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n  width: 500px;\n  height: 281px;\n}\n\nvideo::-webkit-media-controls-panel {\n  background-image: linear-gradient(transparent, transparent) !important;\n}\n\n.IA_button_2AAGKyTs3a7mKkxRRQQolq {\n  position: relative;\n}\n\n.IA_hide_2ayIJ2TaOUocpsP2AI_OUr {\n  position: relative;\n  width: 100%;\n  margin-top: 30px;\n  cursor: pointer;\n  font-size: 16px;\n  text-align: center;\n}\n\n.IA_close_2VaUN9ax-IBYOsd4OTtM7z {\n  width: 20px;\n  height: 20px;\n  position: absolute;\n  right: 10px;\n  padding-top: 1px;\n  top: 10px;\n  box-sizing: border-box;\n  border-radius: 5px;\n  z-index: 999999;\n}\n\n.IA_title_3fmz_F7tiNMewQ-ZO_xhkH {\n  width: 100%;\n  text-align: center;\n  margin-top: 10px;\n  color: gray;\n}", ""]);

// Exports
exports.locals = {
	"IA_gettingStartedOverlay": "IA_gettingStartedOverlay_1adekEgCezaZYHusBYrzoq",
	"IA_headline": "IA_headline_3EoAiWAdYlWVE181TaWFVn",
	"IA_text": "IA_text_1spS4mAI0ulrQUffZZowqT",
	"IA_video": "IA_video_3blFqvv02g5YdTVwZDfsje",
	"IA_button": "IA_button_2AAGKyTs3a7mKkxRRQQolq",
	"IA_hide": "IA_hide_2ayIJ2TaOUocpsP2AI_OUr",
	"IA_close": "IA_close_2VaUN9ax-IBYOsd4OTtM7z",
	"IA_title": "IA_title_3fmz_F7tiNMewQ-ZO_xhkH"
};