exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_gallery_2Jqar-O_Fzav-JTHVM3c6R {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.IA_image_X_MzpKOwmLWRoCYzExWdS {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_imageWithSlidingTransition_BciWNL6g4OB0v-2M5lmZu {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  float: left;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_thumbnails_3fFhn8ildSZKTPDZVc2pgT {\n  width: 100%;\n  position: absolute;\n  bottom: 0;\n  background-color: rgb(51,51,51);\n}\n\n.IA_thumbnailsContainer_3tt8rU9pw_IovPydBPa06h {\n  position: relative;\n  height: 100%;\n  width: max-content;\n  transition: margin-left 500ms ease-in-out;\n}\n\n.IA_thumbnail_2qJUjaA9dyJ-mQ5Q4XyAn1 {\n  width: 100px;\n  height: 100px;\n  float: left;\n  background-size: cover;\n  background-position: center;\n  transition: opacity 500ms ease-in-out;\n}", ""]);

// Exports
exports.locals = {
	"IA_gallery": "IA_gallery_2Jqar-O_Fzav-JTHVM3c6R",
	"IA_image": "IA_image_X_MzpKOwmLWRoCYzExWdS",
	"IA_imageWithSlidingTransition": "IA_imageWithSlidingTransition_BciWNL6g4OB0v-2M5lmZu",
	"IA_thumbnails": "IA_thumbnails_3fFhn8ildSZKTPDZVc2pgT",
	"IA_thumbnailsContainer": "IA_thumbnailsContainer_3tt8rU9pw_IovPydBPa06h",
	"IA_thumbnail": "IA_thumbnail_2qJUjaA9dyJ-mQ5Q4XyAn1"
};