exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_footer_3xkiAZAGygS84B3wROXjbA {\n  position: absolute;\n  width: 100%;\n  left: 0\n}\n\n.IA_footerWeatherAndClockContainer_Xii8xdM1_eARgV52gwGLd {\n  height: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.IA_logo_1Vj6Z2DBJFa_RbGEd8Z_aY {\n  height: 100%;\n  width: 50%;\n  background-repeat: no-repeat;\n  background-size: contain;\n  position: relative;\n  float: left;\n}\n\n.IA_clock_2ElI0EcKPQiSPk4YBqcIsl {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n.IA_time_1SLyd6sHYOqxvun8izz9Xx {\n  position: relative;\n  float: right;\n  text-align: left;\n  margin-left: 19%;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_timeDivider_33AVj2rSA-p3rqH9jPLGO2 {\n  margin: 0 2px 0 2px;\n}\n\n.IA_date_1lji6toHV9w5Dihsty8vOz {\n  position: relative;\n  float: right;\n  text-align: right;\n  font-weight: lighter;\n}\n\n\n.IA_weather_RlXHM4-jL_tDnknoj9ihM {\n  height: 100%;\n  position: relative;\n  float: right;\n  box-sizing: border-box;\n}\n\n.IA_weatherIcon_3QrhL7EgVhPEsQsMLzpPA0 {\n  position: relative;\n  text-align: left;\n}\n\n.IA_weatherTemperature_3xFsoqyJ2Aeh0SJ_zG3sLF {\n  position: relative;\n  float: right;\n  text-align: left;\n  width: 50%;\n\n}\n\n.IA_weatherArea_e_N_jn62z8Zxf_AUi3nVt {\n  position: relative;\n  float: right;\n  text-align: left;\n  font-weight: lighter;\n  width: 50%;\n  clear: right;\n\n}", ""]);

// Exports
exports.locals = {
	"IA_footer": "IA_footer_3xkiAZAGygS84B3wROXjbA",
	"IA_footerWeatherAndClockContainer": "IA_footerWeatherAndClockContainer_Xii8xdM1_eARgV52gwGLd",
	"IA_logo": "IA_logo_1Vj6Z2DBJFa_RbGEd8Z_aY",
	"IA_clock": "IA_clock_2ElI0EcKPQiSPk4YBqcIsl",
	"IA_time": "IA_time_1SLyd6sHYOqxvun8izz9Xx",
	"IA_timeDivider": "IA_timeDivider_33AVj2rSA-p3rqH9jPLGO2",
	"IA_date": "IA_date_1lji6toHV9w5Dihsty8vOz",
	"IA_weather": "IA_weather_RlXHM4-jL_tDnknoj9ihM",
	"IA_weatherIcon": "IA_weatherIcon_3QrhL7EgVhPEsQsMLzpPA0",
	"IA_weatherTemperature": "IA_weatherTemperature_3xFsoqyJ2Aeh0SJ_zG3sLF",
	"IA_weatherArea": "IA_weatherArea_e_N_jn62z8Zxf_AUi3nVt"
};