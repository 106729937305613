exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_2Y2_D_tLx-2cWAsdKQaMLj {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.likesAndComments_2Y2_D_tLx-2cWAsdKQaMLj .likes_IQM_ZE13Eki-ipCvG4RNl {\n  height: 20px;\n  float: right;\n  position: relative;\n  box-sizing: border-box;\n}\n\n.likesAndComments_2Y2_D_tLx-2cWAsdKQaMLj .spinner_2dYKNMLyc_R_3TD1ReZObm {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_3xKiN07SHuVs_t38J1wSHY 700ms linear infinite;\n  -moz-animation: spin_3xKiN07SHuVs_t38J1wSHY 700ms linear infinite;\n  animation: spin_3xKiN07SHuVs_t38J1wSHY 700ms linear infinite;\n}\n\n.likesAndComments_2Y2_D_tLx-2cWAsdKQaMLj .likesText_34-N5NnS-66tZsISlZzlD- {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n.likesAndComments_2Y2_D_tLx-2cWAsdKQaMLj .likesTextVertical_REVGq_5y27w6R39_IDpPc {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_2Y2_D_tLx-2cWAsdKQaMLj",
	"likes": "likes_IQM_ZE13Eki-ipCvG4RNl",
	"spinner": "spinner_2dYKNMLyc_R_3TD1ReZObm",
	"spin": "spin_3xKiN07SHuVs_t38J1wSHY",
	"likesText": "likesText_34-N5NnS-66tZsISlZzlD-",
	"likesTextVertical": "likesTextVertical_REVGq_5y27w6R39_IDpPc"
};