exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_companyInformation_1jXrkQ9WyVXHia5D8hHsl9 {\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_divider_gUbWhMaw3aqxFdRkCoLzH, .IA_dividerDarkMode_xTuxcvpWK6MmsQYiywzWs {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_billingInfo_1mcBvmLvKD470g4380wOF9 {\n  font-size: 14px;\n}", ""]);

// Exports
exports.locals = {
	"IA_companyInformation": "IA_companyInformation_1jXrkQ9WyVXHia5D8hHsl9",
	"IA_divider": "IA_divider_gUbWhMaw3aqxFdRkCoLzH",
	"IA_dividerDarkMode": "IA_dividerDarkMode_xTuxcvpWK6MmsQYiywzWs",
	"IA_billingInfo": "IA_billingInfo_1mcBvmLvKD470g4380wOF9"
};