exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);", ""]);

// Module
exports.push([module.id, ".IA_counterBackground_okKJ8o8Y1f7orr9Ihnxrw {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_counterBackgroundOverlay_1Dxf5FxqaJNQumvq5Lix-n {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_content_26mcnbiMjJJSJ2xBDuycYK {\n  width: 100%;\n  position: absolute;\n  color: #ffffff;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n}\n\n.IA_headline_1c8hwVF-ugFSUn1JhXj3H2 {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_description_sxGs-QrvfiP9KQKPvGVG1 {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_counter_1wXyKrD0LoHThnfCjCe7Zo {\n  display: flex;\n  flex-wrap: nowrap;\n  text-align: center;\n}\n\n.IA_days_3Qqd6YwWHw1yNr4AbMt07H {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_hours_2dR9-ZsTVt-DCKEyERe8Gz {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_minuts_G1qvLJWJFYz1_V_quZThE {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_seconds_qsD1vzmr9ZIeLlr2OU5cP {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_number_1CVnGv_avFtiFfoC2Ouqxx {\n  width: 100%;\n  font-family: \"Droid Sans Mono\", monospace;\n}\n\n.IA_numberInfo_3ArQ3KaxTyjBWhu9uhj2P3 {\n  width: 100%;\n  font-weight: lighter;\n}", ""]);

// Exports
exports.locals = {
	"IA_counterBackground": "IA_counterBackground_okKJ8o8Y1f7orr9Ihnxrw",
	"IA_counterBackgroundOverlay": "IA_counterBackgroundOverlay_1Dxf5FxqaJNQumvq5Lix-n",
	"IA_content": "IA_content_26mcnbiMjJJSJ2xBDuycYK",
	"IA_headline": "IA_headline_1c8hwVF-ugFSUn1JhXj3H2",
	"IA_description": "IA_description_sxGs-QrvfiP9KQKPvGVG1",
	"IA_counter": "IA_counter_1wXyKrD0LoHThnfCjCe7Zo",
	"IA_days": "IA_days_3Qqd6YwWHw1yNr4AbMt07H",
	"IA_hours": "IA_hours_2dR9-ZsTVt-DCKEyERe8Gz",
	"IA_minuts": "IA_minuts_G1qvLJWJFYz1_V_quZThE",
	"IA_seconds": "IA_seconds_qsD1vzmr9ZIeLlr2OU5cP",
	"IA_number": "IA_number_1CVnGv_avFtiFfoC2Ouqxx",
	"IA_numberInfo": "IA_numberInfo_3ArQ3KaxTyjBWhu9uhj2P3"
};