exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_spider_1AWqJ3tbsII6zuOUPUXLO5 {\n  position: absolute;\n  display: inline-block;\n  top: 0;\n  right: 10%;\n\n  /* 4. Animatie */\n  animation: swing_1Kn7p_89ZBU6SIhqfZ7uCP 3s infinite;\n  transform-origin: top;\n\n  /* Bonus */\n  transition: 2s ease-in-out;\n}\n\n.IA_spiderWeb_2nA5RfAZFBFxgsymv1dXIx {\n  background: rgba(255, 255, 255, .7);\n}\n\n.IA_spiderBody_9kCKmLMYT5O1jhUuB3jHf {\n  position: relative;\n  background-image: url(\"https://intraactivestorage.blob.core.windows.net/cdn/play/spider.png\");\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n.IA_spiderLeftEye__kE1YE22PTklL_b961yPk {\n  position: absolute;\n  background: #fff;\n  border-radius: 50%;\n}\n\n.IA_spiderLeftEye__kE1YE22PTklL_b961yPk:after {\n  background: red;\n  width: 32%;\n  height: 32%;\n  content: '';\n  display: block;\n  margin: 55%;\n  border-radius: 50%;\n\n  /* 3. Animatie */\n  animation: look_30PUBiM293rXnmh29Yg2nu 8s infinite;\n}\n\n.IA_spiderRightEye_1NS3KIJ-M_K6bltrJoD0nI {\n  position: absolute;\n  background: #fff;\n  border-radius: 50%;\n}\n\n.IA_spiderRightEye_1NS3KIJ-M_K6bltrJoD0nI:after {\n  background: red;\n  width: 32%;\n  height: 32%;\n  content: '';\n  display: block;\n  margin: 55%;\n  border-radius: 50%;\n\n  /* 3. Animatie */\n  animation: look_30PUBiM293rXnmh29Yg2nu 8s infinite;\n}\n\n/* 1. Animatie */\n@keyframes look_30PUBiM293rXnmh29Yg2nu {\n  0%, 40%, 100% {\n    transform: translateX(0);\n  }\n\n  45%, 95% {\n    transform: translateX(-110%);\n  }\n}\n\n\n/* 3. Animatie */\n@keyframes swing_1Kn7p_89ZBU6SIhqfZ7uCP {\n  0%, 100% {\n    transform: translateY(0);\n  }\n\n  50% {\n    transform: translateY(-20px);\n  }\n}", ""]);

// Exports
exports.locals = {
	"IA_spider": "IA_spider_1AWqJ3tbsII6zuOUPUXLO5",
	"swing": "swing_1Kn7p_89ZBU6SIhqfZ7uCP",
	"IA_spiderWeb": "IA_spiderWeb_2nA5RfAZFBFxgsymv1dXIx",
	"IA_spiderBody": "IA_spiderBody_9kCKmLMYT5O1jhUuB3jHf",
	"IA_spiderLeftEye": "IA_spiderLeftEye__kE1YE22PTklL_b961yPk",
	"look": "look_30PUBiM293rXnmh29Yg2nu",
	"IA_spiderRightEye": "IA_spiderRightEye_1NS3KIJ-M_K6bltrJoD0nI"
};