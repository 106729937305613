exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_playlists_1KR867xRGOXk3kvg950WGw {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_2iwNNpQ3I8PI6HEV-rBlf0 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_3JnB1ARSHnEkDPGPFfjpbY {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_1ko6eaC7pRWQVpD_r7BsCH {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_playlists": "IA_playlists_1KR867xRGOXk3kvg950WGw",
	"IA_filters": "IA_filters_2iwNNpQ3I8PI6HEV-rBlf0",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_3JnB1ARSHnEkDPGPFfjpbY",
	"IA_paginationPageCount": "IA_paginationPageCount_1ko6eaC7pRWQVpD_r7BsCH"
};