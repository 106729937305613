exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_mediaFiles_M9m9MgMGAbLh0IVlf7TF1 {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_2rlzFH51ZN1ryjr06NN-5m {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_IzY7oe-psdM3cCyFpj3yn {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_1mRGzuw5rNJG72N4Pjbn1r {\n  text-align: center;\n}\n\n.IA_tagButton_3DmrBhLfCmOf6D1tz9MM-I {\n  padding: 5px 7px;\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n}\n\n.IA_tagButtonWithDelete_MR8MTgnAKXC3xZ0FhwvOr {\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n  overflow: hidden;\n}\n\n.IA_tagButtonFlexContainer_W-QFYO-Lwg59mgPEiKKMZ {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.IA_tagButtonTagName_yZ2J29hso1USj3OIFYIZR {\n  padding: 3px 7px 5px;\n}\n\n.IA_tagButtonIcon_3JqXoWGmT_gKryQf0Zsy-6 {\n  padding: 3px 3px 5px;\n}\n\n.IA_tagButtonIcon_3JqXoWGmT_gKryQf0Zsy-6:hover{\n  background-color: rgba(0,0,0,0.2);\n}", ""]);

// Exports
exports.locals = {
	"IA_mediaFiles": "IA_mediaFiles_M9m9MgMGAbLh0IVlf7TF1",
	"IA_filters": "IA_filters_2rlzFH51ZN1ryjr06NN-5m",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_IzY7oe-psdM3cCyFpj3yn",
	"IA_paginationPageCount": "IA_paginationPageCount_1mRGzuw5rNJG72N4Pjbn1r",
	"IA_tagButton": "IA_tagButton_3DmrBhLfCmOf6D1tz9MM-I",
	"IA_tagButtonWithDelete": "IA_tagButtonWithDelete_MR8MTgnAKXC3xZ0FhwvOr",
	"IA_tagButtonFlexContainer": "IA_tagButtonFlexContainer_W-QFYO-Lwg59mgPEiKKMZ",
	"IA_tagButtonTagName": "IA_tagButtonTagName_yZ2J29hso1USj3OIFYIZR",
	"IA_tagButtonIcon": "IA_tagButtonIcon_3JqXoWGmT_gKryQf0Zsy-6"
};