exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_pivot_hwJl4VvqX9v5WuuA1nNwU {\n  width: 100%;\n  margin-bottom: 20px;\n  font-size: 12px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.IA_pivot_hwJl4VvqX9v5WuuA1nNwU .IA_pivotItem_1pucrFgYlOFzqS-U40x57l {\n  height: 25px;\n  line-height: 25px;\n  width: auto;\n  margin-right: 20px;\n  cursor: pointer;\n}\n\n.IA_tabItem_viotGySOyE1W23ISZBMJM {\n  float: left;\n  height: 40px;\n  line-height: 40px;\n  text-align: center;\n  cursor: pointer;\n  border-right: 1px solid #ffffff;\n  box-sizing: border-box;\n}\n\n.IA_tabItemNumber_1lGJeqPgbGWaw7wdWFtoCu {\n  float: left;\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  margin-top: 10px;\n  line-height: 20px;\n  margin-left: 20px;\n}\n\n.IA_tabItemIcon_2cHl9zZ2kICAkIlDIIieev {\n  float: left;\n  height: 20px;\n  border-radius: 20px;\n  line-height: 20px;\n}\n\n.IA_tabItemText_AOT6rPIbXIqK8gmKkDC97 {\n  float: left;\n  height: 20px;\n  border-radius: 20px;\n  margin-left: 10px;\n  max-width: 84px;\n}", ""]);

// Exports
exports.locals = {
	"IA_pivot": "IA_pivot_hwJl4VvqX9v5WuuA1nNwU",
	"IA_pivotItem": "IA_pivotItem_1pucrFgYlOFzqS-U40x57l",
	"IA_tabItem": "IA_tabItem_viotGySOyE1W23ISZBMJM",
	"IA_tabItemNumber": "IA_tabItemNumber_1lGJeqPgbGWaw7wdWFtoCu",
	"IA_tabItemIcon": "IA_tabItemIcon_2cHl9zZ2kICAkIlDIIieev",
	"IA_tabItemText": "IA_tabItemText_AOT6rPIbXIqK8gmKkDC97"
};