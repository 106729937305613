exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_menuItem_1XmwOPZi_AhScO_Nkzvffl, .IA_menuItemDark_2I3lQkxEYqhC8cz4JRxvRz {\n  position: relative;\n  float: left;\n  height: 50px;\n  line-height: 50px;\n  width: 100%;\n  cursor: pointer;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\n.IA_menuItem_1XmwOPZi_AhScO_Nkzvffl:hover {\n  background-color: #eeeeee;\n}\n\n.IA_menuItemDark_2I3lQkxEYqhC8cz4JRxvRz:hover {\n  background-color: #414141;\n}\n\n.IA_menuItemIcon_1zJboYpcAx-uQKCFEl4EEb {\n  position: relative;\n  float: left;\n  height: 50px;\n  line-height: 50px;\n  width: 50px;\n  margin-left: -3px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.IA_menuItemText_3UnazxA6CrLJcGN-2QPJMv {\n  position: relative;\n  height: 50px;\n  line-height: 50px;\n  width: calc(100% - 50px);\n  box-sizing: border-box;\n}", ""]);

// Exports
exports.locals = {
	"IA_menuItem": "IA_menuItem_1XmwOPZi_AhScO_Nkzvffl",
	"IA_menuItemDark": "IA_menuItemDark_2I3lQkxEYqhC8cz4JRxvRz",
	"IA_menuItemIcon": "IA_menuItemIcon_1zJboYpcAx-uQKCFEl4EEb",
	"IA_menuItemText": "IA_menuItemText_3UnazxA6CrLJcGN-2QPJMv"
};