import * as React from 'react';
import { IMessageCategory } from '../../../interfaces/IMessage';
import { IAMessageCategory } from './MessageCategory';
import { IATooltip } from '../../tooltip/Tooltip';
import { Helper } from '../../../Helper';
import { IFontSlots } from '../../../interfaces/IFontSlots';

export interface IAMessageCategoriesProps {
  categories: IMessageCategory[];
  showOverflowCounter?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  disablePopup?: boolean;
  textColorWhenTransparentBackground?: string;
  fontSlots?: IFontSlots;
  style?: React.CSSProperties;
  archiveLink?: string;
}

export interface IAMessageCategoriesState {
  overflowingCounter: number;
  overflowCounterLeftPosition: number
  useTransparentBackground: boolean;
}

export class IAMessageCategories extends React.Component<IAMessageCategoriesProps, IAMessageCategoriesState> {
  private outerContainer: HTMLDivElement;
  private categoriesContainer: HTMLDivElement;

  private resizeObserver: ResizeObserver;

  constructor(props: IAMessageCategoriesProps) {
    super(props);
    this.state = {
      overflowingCounter: undefined,
      overflowCounterLeftPosition: 0,
      useTransparentBackground: false
    }
  }

  componentDidMount(): void {
    if (this.props.showOverflowCounter) {
      this.setOverflowCounter(true);
    }
    this.setUseTransparentBackground();
    this.resizeObserver = new ResizeObserver(() => this.resizeListener());
    this.resizeObserver.observe(this.outerContainer);
  }

  componentDidUpdate(prevProps: Readonly<IAMessageCategoriesProps>): void {
    if (prevProps.categories !== this.props.categories) {
      this.setUseTransparentBackground();
      if (this.props.showOverflowCounter) {
        this.setOverflowCounter(true);
      }
    }
  }

  private setUseTransparentBackground(){
    let useTransparentBackground = true;
    this.props.categories?.forEach(category => {
      if (category.bgColor || (category.textColor && category.textColor !== "#000000")) {
        useTransparentBackground = false;
      }
    });
    this.setState({ useTransparentBackground });
  }

  private resizeListener() {
    this.setOverflowCounter(true);
  }

  private setOverflowCounter(doubleCheck?: boolean) {
    if (this.categoriesContainer) {
      let counter = 0;
      let counterLeftPosition = 0;
      const containerBottom = this.categoriesContainer.getBoundingClientRect().bottom;
      const children = Array.from(this.categoriesContainer.children);
      children.forEach(category => {
        if (category.getBoundingClientRect().top > containerBottom - 2) {
          counter++;
        }
      });
      children.forEach((category, i) => {
        if (children.length - counter > i) {
          counterLeftPosition += category.getBoundingClientRect().width + Helper.messageCategoryMargin;
        }
      });
      if (counter) {
        this.setState({ overflowingCounter: counter, overflowCounterLeftPosition: counterLeftPosition + (this.props.paddingLeft ?? 0) }, () => {
          // we need to double check since one more item might disappear when we add the +X div
          if (doubleCheck) {
            this.setOverflowCounter();
          }
        });
      } else {
        this.setState({ overflowingCounter: undefined });
      }
    }
  }

  public render(): JSX.Element {
    return <div
      ref={(ref) => this.outerContainer = ref}
      style={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        height: this.props.showOverflowCounter ? 23 : undefined,
        paddingLeft: this.props.paddingLeft,
        paddingRight: this.props.paddingRight,
        paddingBottom: this.props.paddingBottom,
        paddingTop: this.props.paddingTop,
        fontFamily: this.props.fontSlots?.body?.fontFamily,
        ...this.props.style
      }}
    >
      <div
        ref={(ref) => this.categoriesContainer = ref}
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-end",
          overflow: this.props.showOverflowCounter ? "hidden" : undefined,
        }}
      >
        {this.props.categories && this.props.categories.map((category, i) => {
          return <IAMessageCategory
            key={category.name}
            category={category}
            isLastCategory={i === this.props.categories.length - 1}
            allowOnlyOneLineOfText={this.props.showOverflowCounter}
            useTransparentAsDefaultBackgroud={this.state.useTransparentBackground}
            textColorWhenTransparentBackground={this.props.textColorWhenTransparentBackground}
            archiveLink={this.props.archiveLink}
          />;
        })}
      </div>
      {this.props.showOverflowCounter && this.state.overflowingCounter &&
        <>
          {/* This is hidden but necessary to have */}
          <div
            style={{
              opacity: 0
            }}
          >
            <IAMessageCategory
              key={"overflowingCounterHidden"}
              category={{
                id: "overflowingCounterHidden",
                name: `+${this.state.overflowingCounter}`
              }}
            />
          </div>
          {/* ==================================== */}
          <IATooltip
            content="Tooltip"
            type={"bubble"}
            styles={{
              width: 220,
              textAlign: "left",
              color: "#333333",
            }}
            borderRadius={5}
            componentContent={
              this.props.disablePopup ? undefined :
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {this.props.categories.map((category, i) => {
                  if (i >= this.props.categories.length - this.state.overflowingCounter) {
                    return <IAMessageCategory
                      key={category.name}
                      category={category}
                      isLastCategory={i === this.props.categories.length - 1}
                    />;
                  }
                })}
              </div>
            }
          >
            <div
              style={{
                position: "absolute",
                left: this.state.overflowCounterLeftPosition
              }}
            >
              <IAMessageCategory
                key={"overflowingCounter"}
                category={{
                  id: "overflowingCounter",
                  name: `+${this.state.overflowingCounter}`
                }}
                useTransparentAsDefaultBackgroud={this.state.useTransparentBackground}
                textColorWhenTransparentBackground={this.props.textColorWhenTransparentBackground}
              />
            </div>
          </IATooltip>
        </>
      }
    </div>;
  }

  componentWillUnmount(): void {
    this.resizeObserver.disconnect();
  }
}