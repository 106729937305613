import * as React from 'react';
import * as styles from "./Likes.css";
import { IUser } from '../../interfaces/IUser';
import { ILike } from '../../interfaces/ILike';
import SocialService from '../../services/SocialService';
import { IAIcon } from '../icon/Icon';
import Picker, { Emoji, EmojiStyle } from 'emoji-picker-react';
import { IATooltip } from '../tooltip/Tooltip';
import { ENTER_KEY, SPACEBAR_KEY } from '../../global/Constants';
import '../../styles/BasicIA.css'

export interface ILikesProps {
  color: string;
  highlightColor: string;
  itemId: string;
  commentId?: string;
  user: IUser;
  tenant: string;
  component: string;
  instance: string;
  token: string;
  source: string;
  environment?: "Development" | "Test" | "Production";
  likes?: ILike[];
  likesUpdated?: (likes: ILike[]) => void;
  style?: React.CSSProperties;
  dataAutomationId?: string;
  displayVertical?: boolean;
  showDimmed?: boolean;
  reactionOptions: string[];
  showEmojiPickerExpandButton: boolean;
  useReactions: boolean;
  showReactionEvenIfUserHasNotReacted?: string;
  hasLikedId?: string;
}

export interface ILikesState {
  showSpinner: boolean;
  likes: ILike[];
  showPicker?: boolean;
  top?: number;
  left?: number;
}
export class IALikes extends React.Component<ILikesProps, ILikesState> {

  private emojiPickerRef: React.RefObject<HTMLDivElement>

  constructor(props: ILikesProps) {
    super(props);
    this.state = {
      showSpinner: false,
      likes: props.likes != undefined ? props.likes : []
    };
    this.emojiPickerRef = React.createRef();
  }

  public componentDidMount(): void {
    if (this.props.likes == undefined) {
      this.setState({ showSpinner: true }, () => this.forceUpdate());
      SocialService.getLikes(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((likes: ILike[]) => {
        this.setState({ showSpinner: false, likes }, () => this.forceUpdate());
        this.props.likesUpdated(likes);
      });
    }
    document.addEventListener('mousedown', (event) => this.handleClick(event));
    document.addEventListener('scroll', () => {
      this.setState({ showPicker: false });
    });
    const scrollView = document.getElementById("spPageCanvasContent");
    if (scrollView?.parentNode?.parentNode?.parentNode) {
      scrollView?.parentNode?.parentNode?.parentNode?.addEventListener('scroll', () => {
        this.setState({ showPicker: false });
      });
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', (event) => this.handleClick(event));
    document.removeEventListener('scroll', () => {
      this.setState({ showPicker: false });
    });
    const scrollView = document.getElementById("spPageCanvasContent");
    if (scrollView?.parentNode?.parentNode?.parentNode) {
      scrollView?.parentNode?.parentNode?.parentNode?.removeEventListener('scroll', () => {
        this.setState({ showPicker: false });
      });
    }
  }

  public componentWillReceiveProps(props: ILikesProps): void {
    if (props.itemId != this.props.itemId || props.likes != this.props.likes) {
      if (props.likes == undefined) {
        this.setState({ showSpinner: true }, () => this.forceUpdate());
        SocialService.getLikes(props.environment, props.tenant, props.component, props.instance, props.token, props.itemId).then((likes: ILike[]) => {
          this.setState({ showSpinner: false, likes }, () => this.forceUpdate());
          props.likesUpdated(likes);
        });
      } else {
        this.setState({ likes: props.likes }, () => this.forceUpdate());
      }
    }
  }

  private handleClick(event: Event) {
    if (this.emojiPickerRef && this.emojiPickerRef.current && !this.emojiPickerRef.current.contains(event.target as Node)) {
      if (this.state.showPicker) {
        this.setState({ showPicker: false })
      }
    }
  }

  private react(reactionType: string) {
    if (this.props.showDimmed) {
      return;
    }
    if (this.state.likes != undefined && this.props.user != undefined && !this.state.showSpinner) {
      this.setState({ showSpinner: true });
      SocialService.like(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.user, reactionType, this.props.itemId, this.props.source, this.props.commentId).then((likes: ILike[]) => {
        this.setState({ likes, showSpinner: false }, () => {
          if (this.props.likesUpdated) {
            this.props.likesUpdated(likes);
          }
        });
      });
    }
  }

  private removeReaction() {
    if (this.props.showDimmed) {
      return;
    }
    if (this.state.likes != undefined && this.props.user != undefined && !this.state.showSpinner) {
      let likeId: string;
      this.setState({ showSpinner: true });
      this.state.likes.forEach((like: ILike) => {
        if (this.props.user && (like.user.userPrincipalName == this.props.user.userPrincipalName)) {
          likeId = like.id;
        }
      });
      SocialService.unlike(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId, likeId, this.props.commentId).then((likes: ILike[]) => {
        this.setState({ likes, showSpinner: false }, () => {
          if (this.props.likesUpdated) {
            this.props.likesUpdated(likes);
          }
        });
      });
    }
  }

  public getMostReactedEmoji(hasLikedWithReaction: string | undefined): string {
    if (!this.state.likes || this.state.likes.length === 0) {
      return undefined;
    }
    const typeFrequency: { [key: string]: number } = {};
    // Count the occurrences of each type
    this.state.likes.forEach((like) => {
      const type = like.type ?? "1f44d";
      typeFrequency[type] = (typeFrequency[type] || 0) + 1;
    });
    // Find the type with the highest frequency
    let mostUsedType: string | undefined = undefined;
    let maxCount = 0;
    let reactCountForUserReaction = 0;
    Object.entries(typeFrequency).forEach(([type, count]) => {
      type = type ? type : "1f44d";
      if (type === hasLikedWithReaction) {
        reactCountForUserReaction = count;
      }
      if (count > maxCount) {
        maxCount = count;
        mostUsedType = type;
      }
    });
    return maxCount === reactCountForUserReaction && hasLikedWithReaction ? hasLikedWithReaction : mostUsedType;
  }

  public render(): JSX.Element {
    let hasLikedWithReaction;
    if (this.state.likes && this.state.likes.length > 0) {
      this.state.likes.forEach((like: ILike) => {
        if (this.props.user && (like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase())) {
          hasLikedWithReaction = like.type ?? "1f44d";
          return;
        }
      });
    }
    let emojiPickerWidth = 324;
    if (this.props.reactionOptions == undefined) {
      emojiPickerWidth = 52;
    } else {
      emojiPickerWidth = (this.props.reactionOptions.length * 26) + 12;
    }
    if (this.props.showEmojiPickerExpandButton) {
      emojiPickerWidth += 26;
    }
    const style = this.props.style ?? {};
    if (!style["--epr-emoji-size"]) {
      style["--epr-emoji-size"] = "16px";
    }
    return this.props.useReactions ?
      (
        <IATooltip
          content="Tooltip"
          type={"bubble"}
          disabled={this.state.likes?.length === 0 || this.state.showPicker}
          styles={{
            textAlign: "left",
            color: "#333333",
            marginBottom: "10px",
          }}
          componentContent={
            <div className={styles.likeTooltip}>
              {this.state.likes.map((like: ILike, index: number) => {
                return (
                  <div
                    key={index}
                    className={styles.likeTooltipItem}
                  >
                    <div
                      className={styles.likeTooltipItemName}
                      style={{
                        fontWeight: like?.user?.userPrincipalName?.toLowerCase() == this.props.user?.userPrincipalName?.toLowerCase() ? "bold" : "normal"
                      }}
                    >
                      {like.user.displayName}
                    </div>
                    <div className={styles.likeTooltipItemEmoji}>
                      <Emoji
                        unified={like.type ?? "1f44d"}
                        emojiStyle={EmojiStyle.NATIVE}
                        size={15}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          }>
          <div
            className={styles.likesAndComments}
            style={{
              height: this.props.displayVertical ? 37 : (this.props.useReactions ? 15 : 20),
              width: this.props.displayVertical ? 40 : undefined,
              marginTop: this.props.displayVertical ? 0 : (this.props.useReactions ? 12.5 : 8),
              opacity: this.props.showDimmed ? 0.5 : undefined,
              lineHeight: this.props.useReactions ? "15px" : "20px",
              ...this.props.style
            }}
          >
            <div
              className={[styles.likes, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              style={{
                opacity: this.props.user != undefined ? 1 : 0.5,
                width: this.props.displayVertical ? 40 : undefined,
                height: this.props.displayVertical ? 37 : 20,
                marginRight: this.props.displayVertical ? 0 : 5,
                padding: undefined
              }}
              data-automation-id={this.props.dataAutomationId}
              onClick={() => {
                if (hasLikedWithReaction != undefined) {
                  this.removeReaction();
                } else {
                  if (this.props.showReactionEvenIfUserHasNotReacted) {
                    if (this.props.hasLikedId) {
                      SocialService.unlike(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId, this.props.hasLikedId, this.props.commentId).then(() => {
                        this.react(this.props.showReactionEvenIfUserHasNotReacted);
                      });
                    } else {
                      this.react(this.props.showReactionEvenIfUserHasNotReacted);
                    }
                  } else {
                    if ((this.props.reactionOptions == undefined || this.props.reactionOptions?.length === 0) && !this.props.showEmojiPickerExpandButton) {
                      this.react("1f44d");
                    } else if (this.props.reactionOptions?.length === 1 && !this.props.showEmojiPickerExpandButton) {
                      this.react(this.props.reactionOptions[0]);
                    } else {
                      this.setState({ showPicker: true });
                    }
                  }
                }
              }}
              // onKeyDown={(event) => {
              //   if (event.key === ENTER_KEY || event.key === SPACEBAR_KEY) {
              //     this.onClick(hasLikedWithReaction != undefined, "1f44d");
              //   }
              // }}
              onMouseEnter={(event) => {
                if (!this.state.showPicker) {
                  const eventTarget: any = event.target;
                  let top;
                  let left = event.clientX - (emojiPickerWidth / 2)
                  const targetTopPosition = Math.floor(eventTarget?.getBoundingClientRect()?.y);
                  const targetBottomPosition = Math.floor(eventTarget?.getBoundingClientRect()?.y + eventTarget?.getBoundingClientRect()?.height);

                  top = targetTopPosition - 50;
                  // If content overflows window top
                  if (top < 0) {
                    top = targetBottomPosition;
                  }
                  // If content overflows window right side
                  if (left + emojiPickerWidth + 20 > window.innerWidth) {
                    left = window.innerWidth - emojiPickerWidth - 30;
                  }
                  // If content overflows window left side
                  if (left < 10) {
                    left = 10;
                  }
                  this.setState({
                    top: top,
                    left: left
                  });
                }
              }}
            >
              <div
                style={{
                  float: "left",
                  marginLeft: this.props.displayVertical ? 10 : 0,
                  marginBottom: 5
                }}
              >
                {this.state.showPicker &&
                  <div
                    ref={this.emojiPickerRef}
                    onClick={(event) => {
                      const eventTarget: any = event.target;
                      const targetTopPosition = Math.floor(eventTarget?.getBoundingClientRect()?.y);
                      let top = targetTopPosition - 50;
                      if (top + 450 > window.innerHeight) {
                        top = window.innerHeight - 460;
                        this.setState({ top }, () => this.forceUpdate());
                      }
                      if (this.state.left - (emojiPickerWidth / 2) + 350 > window.innerWidth) {
                        const left = window.innerWidth - 360;
                        this.setState({ left }, () => this.forceUpdate());
                      }
                    }}
                    style={{
                      position: "fixed",
                      top: this.state.top,
                      left: this.state.left,
                      zIndex: 1000000,
                      transition: "all 0.2s ease-in-out",
                    }}
                  >
                    {(this.props.reactionOptions != undefined || this.props.showEmojiPickerExpandButton) &&
                      <Picker
                        reactionsDefaultOpen={true}
                        emojiStyle={EmojiStyle.NATIVE}
                        onEmojiClick={(event) => {
                          this.react(event.unified);
                          this.setState({ showPicker: false });
                        }}
                        style={style}
                        reactions={this.props.reactionOptions}
                        allowExpandReactions={this.props.showEmojiPickerExpandButton != undefined ? this.props.showEmojiPickerExpandButton : false}
                      />
                    }
                  </div>
                }
                {this.state.showSpinner || this.state.likes?.length < 1 &&
                  <IAIcon
                    url={"https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_regular.svg"}
                    color={this.props.highlightColor}
                    size={17}
                    style={{
                      opacity: this.state.showSpinner ? 0.5 : 1,
                      marginLeft: this.props.displayVertical ? 1 : 0,
                      marginTop: -2
                    }}
                  />
                }
                {this.state.likes?.length > 0 && !this.state.showSpinner &&
                  <div
                    style={{
                      marginLeft: this.props.displayVertical ? 2 : 0,
                      height: 20,
                      marginTop: -2,
                      width: 15,
                      marginRight: 3
                    }}
                  >
                    <Emoji
                      unified={this.props.showReactionEvenIfUserHasNotReacted ?? this.getMostReactedEmoji(hasLikedWithReaction)}
                      emojiStyle={EmojiStyle.NATIVE}
                      size={15}
                    />
                  </div>
                }
              </div>
              <div
                className={this.props.displayVertical ? styles.likesTextVertical : styles.likesText}
                style={{
                  color: this.props.highlightColor,
                  fontWeight: hasLikedWithReaction == undefined ? "normal" : "bold",
                  opacity: this.state.showSpinner ? 0.5 : 1,
                }}
              >
                {this.state.likes != undefined ? this.state.likes.length : "-"}
              </div>
            </div>
          </div >
        </IATooltip>
      )
      :
      (
        <div
          className={styles.likesAndComments}
          style={{
            height: this.props.displayVertical ? 37 : 20,
            width: this.props.displayVertical ? 40 : undefined,
            marginTop: this.props.displayVertical ? 0 : (this.props.useReactions ? 8 : 10),
            opacity: this.props.showDimmed ? 0.5 : undefined,
            ...this.props.style
          }}
        >
          <div
            className={[styles.likes, "IA_OnFocus"].join(" ")}
            tabIndex={0}
            style={{
              opacity: this.props.user != undefined ? 1 : 0.5,
              width: this.props.displayVertical ? 40 : undefined,
              height: this.props.displayVertical ? 37 : 20,
              marginRight: this.props.displayVertical ? 0 : 5
            }}
            data-automation-id={this.props.dataAutomationId}
            onClick={(event) => this.onClick(event, hasLikedWithReaction != undefined)}
            onKeyDown={(event) => {
              if (event.key === ENTER_KEY || event.key === SPACEBAR_KEY) {
                this.onClick(event, hasLikedWithReaction != undefined);
              }
            }}
            onMouseEnter={() => {
              this.setState({
                showPicker: true,
              });
            }}
            onMouseMove={() => {
              this.setState({
                showPicker: true,
              })
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                this.setState({
                  showPicker: false
                });
              }, 0);
            }}
          >
            <div
              style={{
                float: "left",
                marginLeft: this.props.displayVertical ? 11 : 0
              }}
            >
              {hasLikedWithReaction != undefined ?
                <IAIcon
                  url={"https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_filled.svg"}
                  color={this.props.highlightColor}
                  size={18}
                  style={{
                    opacity: this.state.showSpinner ? 0.5 : 1
                  }}
                />
                :
                <IAIcon
                  url={"https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_regular.svg"}
                  color={this.props.highlightColor}
                  size={18}
                  style={{
                    opacity: this.state.showSpinner ? 0.5 : 1
                  }}
                />
              }
            </div>
            <div
              className={this.props.displayVertical ? styles.likesTextVertical : styles.likesText}
              style={{
                color: this.props.highlightColor,
                fontWeight: hasLikedWithReaction == undefined ? "normal" : "bold",
                opacity: this.state.showSpinner ? 0.5 : 1,
              }}
            >
              {this.state.likes != undefined ? this.state.likes.length : "-"}
            </div>
          </div>
        </div >
      );
  }

  // Old likes feature

  private onClick(e: React.SyntheticEvent<HTMLDivElement>, hasLiked: boolean) {
    if (this.props.showDimmed) {
      return;
    }
    e.stopPropagation();
    if (this.state.likes != undefined && this.props.user != undefined && !this.state.showSpinner) {
      this.setState({ showSpinner: true });
      if (hasLiked) {
        let likeId: string;
        this.state.likes.forEach((like: ILike) => {
          if (this.props.user && (like.user.userPrincipalName == this.props.user.userPrincipalName)) {
            likeId = like.id;
          }
        });
        SocialService.unlike(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId, likeId, this.props.commentId).then((likes: ILike[]) => {
          this.setState({ likes, showSpinner: false }, () => {
            if (this.props.likesUpdated) {
              this.props.likesUpdated(likes);
            }
          });
        });
      } else {
        SocialService.like(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.user, undefined, this.props.itemId, this.props.source, this.props.commentId).then((likes: ILike[]) => {
          this.setState({ likes, showSpinner: false }, () => {
            if (this.props.likesUpdated) {
              this.props.likesUpdated(likes);
            }
          });
        });
      }
    }
  }
}