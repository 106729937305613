exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_group_38udHSH2i0xLjaW9Aq0jNe {\n  width: 100%;\n  clear: both;\n  margin-bottom: 10px;\n  float: left;\n}\n\n.IA_line_3jB-OlEQSozYRJvsf53Klo {\n  width: calc(50% - 150px);\n  height: 1px;\n  background-color: #eeeeee;\n  float: left;\n  margin-top: 8px;\n}\n\n.IA_title_26X879DJKc2nxDER_NKdkD {\n  font-size: 14px;\n  cursor: pointer;\n  font-weight: bold;\n  text-align: center;\n  float: left;\n}\n\n.IA_groupArea_CvpjMDZmscgPMbU_epq1J { \n  height: auto;\n  width: 100%;\n  box-sizing: border-box;\n  margin-top: 10px;\n  position: relative;\n  float: left;\n  margin-bottom: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_group": "IA_group_38udHSH2i0xLjaW9Aq0jNe",
	"IA_line": "IA_line_3jB-OlEQSozYRJvsf53Klo",
	"IA_title": "IA_title_26X879DJKc2nxDER_NKdkD",
	"IA_groupArea": "IA_groupArea_CvpjMDZmscgPMbU_epq1J"
};