exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_articles_100p0-hULgUeigrQIVAbkI {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_2Bw7kLV2Qpt3K0BA3admRU {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_3LpslnX1sK19aYZKKwmfOF {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2m1mjmIQi0eFlbvcN0-_j6 {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_articles": "IA_articles_100p0-hULgUeigrQIVAbkI",
	"IA_filters": "IA_filters_2Bw7kLV2Qpt3K0BA3admRU",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_3LpslnX1sK19aYZKKwmfOF",
	"IA_paginationPageCount": "IA_paginationPageCount_2m1mjmIQi0eFlbvcN0-_j6"
};