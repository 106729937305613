import * as React from 'react';
import * as styles from "./UniqueViews.css";
import { IUser } from '../../interfaces/IUser';
import { IAIcon } from '../icon/Icon';

export interface IUniqueViewsProps {
  color: string;
  itemId: string;
  user: IUser;
  tenant: string;
  component?: string;
  instance: string;
  token: string;
  source: string;
  environment?: "Development" | "Test" | "Production";
  uniqueViews?: number;
  style?: React.CSSProperties;
  dataAutomationId?: string;
  displayVertical?: boolean;
  showDimmed?: boolean;
}

export interface IUniqueViewsState {
  showSpinner: boolean;
}

export class IAUniqueViews extends React.Component<IUniqueViewsProps, IUniqueViewsState> {

  constructor(props: IUniqueViewsProps) {
    super(props);
  }

  public render(): JSX.Element {
    var uniqueViews;
    if (this.props.uniqueViews == undefined) {
      uniqueViews = 0;
    } else if (this.props.uniqueViews > 999) {
      // Example: turns 1242 into 1,2k
      uniqueViews = ((this.props.uniqueViews / 1000).toFixed(1).replace(/\.0$/, '') + 'k').replace('.', ',');
    } else {
      uniqueViews = this.props.uniqueViews;
    }

    return (this.props.uniqueViews || this.props.showDimmed ?
      <div
        className={styles.likesAndComments}
        style={{
          height: this.props.displayVertical ? 37 : 22,
          width: this.props.displayVertical ? 40 : undefined,
          opacity: this.props.showDimmed ? 0.5 : undefined,
          ...this.props.style
        }}
      >
        <div
          className={styles.likes}
          style={{
            opacity: this.props.user != undefined ? 1 : 0.5,
            width: this.props.displayVertical ? 40 : undefined,
            height: this.props.displayVertical ? 37 : 22,
            marginRight: this.props.displayVertical ? 0 : 5
          }}
          data-automation-id={this.props.dataAutomationId}
        >
          <div
            style={{
              float: "left",
              marginLeft: this.props.displayVertical ? 11 : 0,
              marginTop: -1
            }}
          >
              <IAIcon
                title={"Preview"}
                color={this.props.color}
                size={20}
              />
          </div>
          <div
            className={this.props.displayVertical ? styles.likesTextVertical : styles.likesText}
            style={{
              color: this.props.color,
            }}
          >
            {uniqueViews}
          </div>
        </div>
      </div >
      : <div></div>
    );
  }
}