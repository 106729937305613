exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_2x3ZA0ZTqpHDXOQzkcQuid {\n  position: relative;\n  float: right;\n  height: 20px;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_2x3ZA0ZTqpHDXOQzkcQuid .likes_3BWW9TaEvxFWk1bi5lzRRg {\n  height: 22px;\n  float: right;\n  position: relative;\n  margin-right: 5px;\n  cursor: pointer;\n  box-sizing: border-box;\n}\n\n.likesAndComments_2x3ZA0ZTqpHDXOQzkcQuid .spinner_3H49zl2Eqfhw_FNZ6y4wYV {\n  height: 18px;\n  width: 18px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_2DKcwtvX_dV6LWTSTngoZE 700ms linear infinite;\n  -moz-animation: spin_2DKcwtvX_dV6LWTSTngoZE 700ms linear infinite;\n  animation: spin_2DKcwtvX_dV6LWTSTngoZE 700ms linear infinite;\n}\n\n.likesAndComments_2x3ZA0ZTqpHDXOQzkcQuid .comments_3CSowJH2KnABZtHS5GnfT {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 3px;\n}\n\n.likesAndComments_2x3ZA0ZTqpHDXOQzkcQuid .likesText_2AVxvu2dz2NkeWWB512iCT {\n  float: left;\n  margin-left: 5px;\n  margin-top: -2px;\n}\n\n.likesAndComments_2x3ZA0ZTqpHDXOQzkcQuid .likesTextVertical_1A3E37Vup8qhErBxFHAJtH {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n.likesAndComments_2x3ZA0ZTqpHDXOQzkcQuid .commentText_2rlE1qRUo4O1dqgYeRhXk {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n@-moz-keyframes spin_2DKcwtvX_dV6LWTSTngoZE {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_2DKcwtvX_dV6LWTSTngoZE {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_2DKcwtvX_dV6LWTSTngoZE {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_2x3ZA0ZTqpHDXOQzkcQuid",
	"likes": "likes_3BWW9TaEvxFWk1bi5lzRRg",
	"spinner": "spinner_3H49zl2Eqfhw_FNZ6y4wYV",
	"spin": "spin_2DKcwtvX_dV6LWTSTngoZE",
	"comments": "comments_3CSowJH2KnABZtHS5GnfT",
	"likesText": "likesText_2AVxvu2dz2NkeWWB512iCT",
	"likesTextVertical": "likesTextVertical_1A3E37Vup8qhErBxFHAJtH",
	"commentText": "commentText_2rlE1qRUo4O1dqgYeRhXk"
};