exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_rotatorViewTemplate_7Gwt6oRDMao6I2r7xHaGC {\n  width: 148px;\n  height: 87px;\n  float: left;\n  margin-top: 10px;\n  border-radius: 5px;\n  overflow: hidden;\n  cursor: pointer;\n  margin-right: 10px;\n  border: 1px solid rgb(221, 221, 221);\n  box-sizing: border-box;\n}\n\n.IA_template_18Qt4Lj5MJwCj9QvN37y6G {\n  float: left;\n  position: relative;\n  width: 148px;\n  height: 87px;\n}\n\n.IA_headline_3H9IcfAKt3i0rBVueX672e {\n  height: 5px;\n  float: left;\n  width: calc(100% - 12px);\n  margin-top: 4px;\n  margin-left: 6px;\n  opacity: 0.6;\n}\n\n.IA_paragraphLine_1exnqbFNgjR6hZuOmg56uc {\n  height: 2px;\n  float: left;\n  width: calc(100% - 12px);\n  margin-top: 4px;\n  margin-left: 6px;\n  opacity: 0.6;\n\n}", ""]);

// Exports
exports.locals = {
	"IA_rotatorViewTemplate": "IA_rotatorViewTemplate_7Gwt6oRDMao6I2r7xHaGC",
	"IA_template": "IA_template_18Qt4Lj5MJwCj9QvN37y6G",
	"IA_headline": "IA_headline_3H9IcfAKt3i0rBVueX672e",
	"IA_paragraphLine": "IA_paragraphLine_1exnqbFNgjR6hZuOmg56uc"
};