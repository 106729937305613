exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenu_2G3yZxja4-DnaSgQMAer8d {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.IA_canteenMenuOverlay_1tMgmxQOWjSu71iVL0-_Zg {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.IA_headline_10vYENd2H-OsdbVhpoOqG {\n  width: 100%;\n  text-align: center;\n  position: relative;\n}\n\n.IA_column_3H6Jxy0sCmA6pNWWFIaZZd {\n  float: left;\n  box-sizing: border-box;\n  height: 100%;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenu": "IA_canteenMenu_2G3yZxja4-DnaSgQMAer8d",
	"IA_canteenMenuOverlay": "IA_canteenMenuOverlay_1tMgmxQOWjSu71iVL0-_Zg",
	"IA_headline": "IA_headline_10vYENd2H-OsdbVhpoOqG",
	"IA_column": "IA_column_3H6Jxy0sCmA6pNWWFIaZZd"
};