exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_settings_2UX8iphH3w65eZAa2GLlOI {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n  overflow-y: auto;\n  padding: 40px;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\n.IA_filters_3p5LYRQAIqaniY0uOneHz1 {\n  width: 100%;\n  margin-bottom: 10px;\n  float: left;\n  clear: both;\n}\n\n.IA_pivotContent_382akX5dLoNWNUzBHXO9Kd {\n  width: 100%;\n  float: left;\n  overflow: hidden;\n}\n\n.IA_settings_2UX8iphH3w65eZAa2GLlOI h3 {\n  font-size: 18px;\n  float: left;\n}\n\n.IA_profileImage_2d36XFSMHu_Y0udvydcLGS {\n  width: 80px;\n  height: 80px;\n  border-radius: 40px;\n  float: left;\n  cursor: pointer;\n  background-size: cover;\n  background-position: center;\n  box-sizing: border-box;\n  border: 1px solid lightgray;\n  margin-top: 10px;\n}\n\n.IA_profileImageEditButton_30f7TsPoRv-dkb7lBaHLJN {\n  width: 20px;\n  height: 20px;\n  border-radius: 10px;\n  margin-left: 60px;\n  margin-top: 60px;\n  box-sizing: border-box;\n  padding-left: 3px;\n  padding-top: 3px;\n}\n\n.IA_profileTitle_3Nq1sq1KKmYza1zsLFL562 {\n  line-height: 80px;\n  height: 80px;\n  font-size: 22px;\n  box-sizing: border-box;\n  padding-left: 20px;\n  float: left;\n  max-width: calc(100% - 112px);\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  display: -webkit-box;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.IA_profileIdentity_EEr8iP-tARtECwQiow4Zl {\n  width: calc(100% + 80px);\n  margin-left: -40px;\n  margin-top: -40px;\n  padding: 20px 40px;\n  box-sizing: border-box;\n  height: 150px;\n}", ""]);

// Exports
exports.locals = {
	"IA_settings": "IA_settings_2UX8iphH3w65eZAa2GLlOI",
	"IA_filters": "IA_filters_3p5LYRQAIqaniY0uOneHz1",
	"IA_pivotContent": "IA_pivotContent_382akX5dLoNWNUzBHXO9Kd",
	"IA_profileImage": "IA_profileImage_2d36XFSMHu_Y0udvydcLGS",
	"IA_profileImageEditButton": "IA_profileImageEditButton_30f7TsPoRv-dkb7lBaHLJN",
	"IA_profileTitle": "IA_profileTitle_3Nq1sq1KKmYza1zsLFL562",
	"IA_profileIdentity": "IA_profileIdentity_EEr8iP-tARtECwQiow4Zl"
};