exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_cardCover_3Q3EfKYxPeW_4RL29YnSWR {\n  background-color: #eeeeee;\n  width: 100%;\n  height: 144px;\n  background-position: center;\n  background-size: cover;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.IA_cardCover_3Q3EfKYxPeW_4RL29YnSWR iframe {\n  border: none;\n}\n\n/* Overlay image */\n\n.IA_coverOverlay_2Uhe54HxI9pQFKv9taYTiG {\n  width: 74px;\n  height: 74px;\n  background: rgba(255, 255, 255, 0.8);\n  position: absolute;\n  color: #333333;\n}\n\n.IA_startDate_2XSCc6vp_uUC_COh0gdFXq {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_startDateendDateDivider_2fLIr9DwyhySd29XoUg-zV {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n  margin-top: 10px;\n}\n\n.IA_endDate_2GzN8zD69SVZspWeBESvZ- {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 6px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_coverOverlayToday_1aYlxSjFltDSHVR4QK6cm- {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n  font-size: 14px;\n}\n\n.IA_coverOverlayMonth_djJa5oZkShEbA_RaL06iX {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n  font-size: 14px;\n}\n\n.IA_coverOverlayDay_pb-08swg3katltYzaZsHG {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-size: 22px;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_cardCover": "IA_cardCover_3Q3EfKYxPeW_4RL29YnSWR",
	"IA_coverOverlay": "IA_coverOverlay_2Uhe54HxI9pQFKv9taYTiG",
	"IA_startDate": "IA_startDate_2XSCc6vp_uUC_COh0gdFXq",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_2fLIr9DwyhySd29XoUg-zV",
	"IA_endDate": "IA_endDate_2GzN8zD69SVZspWeBESvZ-",
	"IA_coverOverlayToday": "IA_coverOverlayToday_1aYlxSjFltDSHVR4QK6cm-",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_djJa5oZkShEbA_RaL06iX",
	"IA_coverOverlayDay": "IA_coverOverlayDay_pb-08swg3katltYzaZsHG"
};