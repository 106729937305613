import * as React from 'react';
import * as styles from "./Comments.css";
import { IAIcon } from '../icon/Icon';
import { IUser } from '../../interfaces/IUser';
import SocialService from '../../services/SocialService';
import { ENTER_KEY, REMOVE_FROM_TABORDER, SPACEBAR_KEY } from '../../global/Constants';

export interface ICommentsProps {
  color: string;
  itemId: string;
  user: IUser;
  tenant: string;
  component: string;
  instance: string;
  token: string;
  environment?: "Development" | "Test" | "Production";
  commentCount?: number;
  commentsUpdated?: (comments: number) => void;
  commentsClick?: () => void;
  style?: React.CSSProperties;
  dataAutomationId?: string;
  displayVertical?: boolean;
  showDimmed?: boolean;
}

export interface ICommentsState {
  showSpinner: boolean;
  comments: number;
}
export class IAComments extends React.Component<ICommentsProps, ICommentsState> {

  constructor(props: ICommentsProps) {
    super(props);
    this.state = {
      showSpinner: false,
      comments: props.commentCount != undefined ? props.commentCount : undefined
    };
  }

  public componentWillReceiveProps(props: ICommentsProps): void {
    if (props.itemId != this.props.itemId || props.commentCount != this.props.commentCount) {
      if (props.commentCount == undefined) {
        if (!props.showDimmed) {
          this.setState({ showSpinner: true }, () => this.forceUpdate());
        }
        SocialService.getCommentsCount(props.environment, props.tenant, props.component, props.instance, props.token, props.itemId).then((comments: number) => {
          this.setState({ showSpinner: false, comments }, () => this.forceUpdate());
          props.commentsUpdated(comments);
        });
      } else {
        this.setState({ comments: props.commentCount }, () => this.forceUpdate());
      }
    }
  }

  public componentDidMount(): void {
    if (this.props.commentCount == undefined) {
      if (!this.props.showDimmed) {
        this.setState({ showSpinner: true }, () => this.forceUpdate());
      }
      SocialService.getCommentsCount(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((comments: number) => {
        this.setState({ showSpinner: false, comments }, () => this.forceUpdate());
        this.props.commentsUpdated(comments);
      });
    }
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.likesAndComments}
        style={{
          height: this.props.displayVertical ? 37 : 20,
          width: this.props.displayVertical ? 40 : undefined,
          marginTop: this.props.displayVertical ? 0 : 8,
          opacity: this.props.showDimmed ? 0.5 : undefined,
          ...this.props.style
        }}
      >
        {!this.state.showSpinner &&
          <div
            className={[styles.likes, "IA_OnFocus"].join(" ")}
            tabIndex={this.props.commentsClick ? 0 : REMOVE_FROM_TABORDER}
            style={{
              width: this.props.displayVertical ? 40 : undefined,
              height: this.props.displayVertical ? 37 : 20,
              marginRight: this.props.displayVertical ? 0 : 5,
              border: "none",
              padding: undefined
            }}
            data-automation-id={this.props.dataAutomationId}
            onClick={this.props.commentsClick && !this.props.showDimmed ? (e) => {
              e.stopPropagation();
              this.props.commentsClick();
            } : undefined}
            onKeyDown={(event) => {
              if ((event.key === ENTER_KEY || event.key === SPACEBAR_KEY) && this.props.commentsClick && !this.props.showDimmed) {
                this.props.commentsClick();
              }
            }}
          >
            <div
              style={{
                float: "left",
                marginLeft: this.props.displayVertical ? 12 : 0
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Comment/SVG/ic_fluent_comment_48_regular.svg"
                color={this.props.color}
                size={18}
              />
            </div>
            <div
              className={this.props.displayVertical ? styles.likesTextVertical : styles.likesText}
              style={{
                color: this.props.color
              }}
            >
              {this.state.comments != undefined ? this.state.comments : "-"}
            </div>
          </div>
        }
        {
          this.state.showSpinner &&
          <div className={styles.likes}>
            <div
              style={{
                float: "left",
                marginLeft: this.props.displayVertical ? 11 : 0
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Comment/SVG/ic_fluent_comment_48_regular.svg"
                color={this.props.color}
                size={18}
              />
            </div>
            <div className={styles.spinner}>
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Spinner%20iOS/SVG/ic_fluent_spinner_ios_20_regular.svg"
                color={this.props.color}
                size={18}
              />
            </div>
          </div>
        }
      </div >
    );
  }
}