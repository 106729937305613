exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaCard_3hYJ3QxJgC_8U3FGaJBEE9{\n  display:flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.IA_personaWrapper_3rn-KZB29DrZ0kJ3I7ZfsT {\n  font-size: 14px;\n  float: left;\n  display: block;\n  width: 100%;\n  padding: 8px 0;\n}\n\n.IA_personaWrapper_3rn-KZB29DrZ0kJ3I7ZfsT .IA_personaProfileImageWrapper_2M3zPNAfWEA36wyCrvVW1X {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n\n.IA_personaSmall_2ApW6wwktMBY7EZ-ejWspf .IA_personaProfileImageText_d00qxlTACpqCalbwN6nCy {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n\n.IA_personaUserPrincipalName_3E6NzqTj8ian4ccsp6WJ63 {\n  font-size: 9px;\n  opacity: 0.8\n}", ""]);

// Exports
exports.locals = {
	"IA_personaCard": "IA_personaCard_3hYJ3QxJgC_8U3FGaJBEE9",
	"IA_personaWrapper": "IA_personaWrapper_3rn-KZB29DrZ0kJ3I7ZfsT",
	"IA_personaProfileImageWrapper": "IA_personaProfileImageWrapper_2M3zPNAfWEA36wyCrvVW1X",
	"IA_personaSmall": "IA_personaSmall_2ApW6wwktMBY7EZ-ejWspf",
	"IA_personaProfileImageText": "IA_personaProfileImageText_d00qxlTACpqCalbwN6nCy",
	"IA_personaUserPrincipalName": "IA_personaUserPrincipalName_3E6NzqTj8ian4ccsp6WJ63"
};