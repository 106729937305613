exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_design_38O7Odj-uRemwkBafyhFAh {\n  width: 100%;\n}\n\n.IA_divider_1tGf63oAbpS6TLDL3r-iQs, .IA_dividerDarkMode_ooTTaif0jiOK_ArZEzt-r {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_dividerDarkMode_ooTTaif0jiOK_ArZEzt-r {\n  background-color: #a8a8a8;\n}\n\n.IA_widgetType_37vCnLjbXcFgNS7ve2gwip {\n  margin-bottom: 15px;\n  width: 75px;\n  cursor: pointer;\n}\n\n.IA_widgetTypeLabel_3Qe92iahgDttqs5tBTSqCP {\n  font-size: 12px;\n  float: left;\n  clear: both;\n  width: 90%;\n  margin-top: 2px;\n}\n\n.IA_swatches_191HtHj6av7rwKFuW38eVi {\n  width: 100%;\n  clear: both;\n  position: relative;\n  float: left;\n}\n\n.IA_fontPreview_2S9qzyLI8TqXcpbqh69HOQ {\n  width: 100%;\n  padding: 40px;\n  float: left;\n  box-sizing: border-box;\n  border-radius: 20px;\n  margin-bottom: 20px;\n}\n\n.IA_fontPreviewLabel_VfFRRufpxybtqzP8E60cb {\n  font-size: 12px;\n  width: 100%;\n  margin-top: -20px;\n  margin-bottom: 20px;\n  opacity: 0.6;\n}\n\n.IA_fontPreviewHeadline_3AzG28Q9kreTyRtWDbksDG {\n  font-size: 30px;\n  width: 100%;\n}\n\n.IA_fontPreviewContent_2Jcq-BQ8uNeOIYf4ak95Rf {\n  font-size: 18px;\n  line-height: 26px;\n  width: 100%;\n  margin-top: 20px;\n}", ""]);

// Exports
exports.locals = {
	"IA_design": "IA_design_38O7Odj-uRemwkBafyhFAh",
	"IA_divider": "IA_divider_1tGf63oAbpS6TLDL3r-iQs",
	"IA_dividerDarkMode": "IA_dividerDarkMode_ooTTaif0jiOK_ArZEzt-r",
	"IA_widgetType": "IA_widgetType_37vCnLjbXcFgNS7ve2gwip",
	"IA_widgetTypeLabel": "IA_widgetTypeLabel_3Qe92iahgDttqs5tBTSqCP",
	"IA_swatches": "IA_swatches_191HtHj6av7rwKFuW38eVi",
	"IA_fontPreview": "IA_fontPreview_2S9qzyLI8TqXcpbqh69HOQ",
	"IA_fontPreviewLabel": "IA_fontPreviewLabel_VfFRRufpxybtqzP8E60cb",
	"IA_fontPreviewHeadline": "IA_fontPreviewHeadline_3AzG28Q9kreTyRtWDbksDG",
	"IA_fontPreviewContent": "IA_fontPreviewContent_2Jcq-BQ8uNeOIYf4ak95Rf"
};